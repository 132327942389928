<template>
  <Layout>
    <WithLocations :items-per-page="itemsPerPage" :filters="filtersByLocStatus">
      <div
        slot-scope="{
          locations,
          areLocationsLoading,
          searchLocations,
          clearLocationsSearch,
          totalPages,
          totalRecords,
          page,
        }"
        class="locations-list"
      >
        <h4>View Location or Create a New Report</h4>
        <BasePagination
          :page="page"
          :total-pages="totalPages ? totalPages : 0"
          :number-of-records="totalRecords ? totalRecords : 0"
          :items-per-page="itemsPerPage"
          :is-loading="areLocationsLoading"
          record-type="Locations"
          @nextPage="nextPage"
          @previousPage="previousPage"
        />
        <VContainer pa-0>
          <VLayout justify-center>
            <VFlex xs12 sm8>
              <BaseSearch
                placeholder="Search Locations by Job Name"
                :messages="
                  $route.query.s
                    ? [`Search results for : '${$route.query.s}'`]
                    : []
                "
                :value="$route.query.s"
                class="px-2"
                color="white"
                @search="searchLocations"
                @clear="clearLocationsSearch"/></VFlex></VLayout
        ></VContainer>

        <transition name="fade-fast" mode="out-in" appear>
          <BaseSpinner v-if="areLocationsLoading" />
          <BaseRecordsList
            v-else-if="locations && locations.length"
            name-key="JOB_NUMBER__IDENTIFIER"
            :asset-type="assetType"
            :records="locations"
          />
          <p v-else-if="$route.query.s && !locations.length"
            >No locations matched the search "{{ $route.query.s }}".</p
          >
          <p v-else>No locations found for this view.</p>
        </transition>

        <BasePagination
          :page="page"
          :total-pages="totalPages ? totalPages : 0"
          :number-of-records="totalRecords ? totalRecords : 0"
          :items-per-page="itemsPerPage"
          :is-loading="areLocationsLoading"
          record-type="Locations"
          @nextPage="nextPage"
          @previousPage="previousPage"
        />
        <template v-if="$route.query.s && !locations">
          <p>No locations matched the search "{{ $route.query.s }}".</p>
        </template>
      </div>
    </WithLocations>
  </Layout>
</template>

<script>
import * as appConfig from '@src/app.config'
import Layout from '@layouts/main'
import WithLocations from '@dataProviders/WithLocations'
import invertStatusFilters from '@utils/invertStatusFilters'

import { HumanFields, LocationStatuses, ObjectTypes } from '@constants/knack'

import { createNamespacedHelpers } from 'vuex'
const { mapState: routeState } = createNamespacedHelpers('route')

export default {
  name: 'LocationsListView',
  components: { Layout, WithLocations },
  metaInfo() {
    return {
      title: `Locations ${
        this.query.page > 1 ? ` - Page ${this.query.page}` : ''
      }`,
      meta: [{ name: 'description', content: appConfig.description }],
    }
  },
  data() {
    return {
      itemsPerPage: 15,
      LocationStatuses,
      assetType: ObjectTypes.LOCATIONS,
    }
  },
  computed: {
    ...routeState(['query']),
    filtersByLocStatus() {
      let allStatuses = this.LocationStatuses

      let acceptedStatuses = [
        allStatuses.READY_TO_WORK,
        allStatuses.WORK_SCHEDULE,
      ]

      let knackKeyField = HumanFields.LOCATIONS.STATUS
      return invertStatusFilters(
        LocationStatuses,
        acceptedStatuses,
        knackKeyField
      )
    }, // filtersByLocStatus
  },
  methods: {
    nextPage() {
      let pageNum = +this.query.page ? +this.query.page + 1 : 2
      this.$router.push({
        query: { ...this.$route.query, page: pageNum },
      })
    }, // nextPage
    previousPage() {
      let pageNum = +this.query.page
      pageNum = pageNum && pageNum > 1 ? pageNum - 1 : 1
      this.$router.push({
        query: { ...this.$route.query, page: pageNum },
      })
    }, // previousPage
  },
}
</script>
