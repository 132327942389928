var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('WithLocations',{attrs:{"items-per-page":_vm.itemsPerPage,"filters":_vm.filtersByLocStatus},scopedSlots:_vm._u([{key:"default",fn:function({
        locations,
        areLocationsLoading,
        searchLocations,
        clearLocationsSearch,
        totalPages,
        totalRecords,
        page,
      }){return _c('div',{staticClass:"locations-list"},[_c('h4',[_vm._v("View Location or Create a New Report")]),_c('BasePagination',{attrs:{"page":page,"total-pages":totalPages ? totalPages : 0,"number-of-records":totalRecords ? totalRecords : 0,"items-per-page":_vm.itemsPerPage,"is-loading":areLocationsLoading,"record-type":"Locations"},on:{"nextPage":_vm.nextPage,"previousPage":_vm.previousPage}}),_c('VContainer',{attrs:{"pa-0":""}},[_c('VLayout',{attrs:{"justify-center":""}},[_c('VFlex',{attrs:{"xs12":"","sm8":""}},[_c('BaseSearch',{staticClass:"px-2",attrs:{"placeholder":"Search Locations by Job Name","messages":_vm.$route.query.s
                  ? [`Search results for : '${_vm.$route.query.s}'`]
                  : [],"value":_vm.$route.query.s,"color":"white"},on:{"search":searchLocations,"clear":clearLocationsSearch}})],1)],1)],1),_c('transition',{attrs:{"name":"fade-fast","mode":"out-in","appear":""}},[(areLocationsLoading)?_c('BaseSpinner'):(locations && locations.length)?_c('BaseRecordsList',{attrs:{"name-key":"JOB_NUMBER__IDENTIFIER","asset-type":_vm.assetType,"records":locations}}):(_vm.$route.query.s && !locations.length)?_c('p',[_vm._v("No locations matched the search \""+_vm._s(_vm.$route.query.s)+"\".")]):_c('p',[_vm._v("No locations found for this view.")])],1),_c('BasePagination',{attrs:{"page":page,"total-pages":totalPages ? totalPages : 0,"number-of-records":totalRecords ? totalRecords : 0,"items-per-page":_vm.itemsPerPage,"is-loading":areLocationsLoading,"record-type":"Locations"},on:{"nextPage":_vm.nextPage,"previousPage":_vm.previousPage}}),(_vm.$route.query.s && !locations)?[_c('p',[_vm._v("No locations matched the search \""+_vm._s(_vm.$route.query.s)+"\".")])]:_vm._e()],2)}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }